<template>
  <div>
    <h1>Início</h1>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "inicio",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Início",
        route: { name: "inicio" },
      },
    ]);
  },
};
</script>
